import * as React from "react";
import { withRouteData } from "react-static";
import { Project } from "src/Data/Project";
import { SiteData } from "src/Data/SiteData";
import { ViewFeaturedProject, ViewProjectArchive } from "src/View/Project";
import styled from "styled-components";
import { Context } from "src/Context";
import { LazyRender } from "src/LazyRender";

const Container = styled.div`
  width: 100%;
`;

interface HomeData {
  featuredProjects: Project.Featured[];
  archivedProjects: Project.Preview.PagedProjects;
}

type HomeProps = HomeData & SiteData;

class ViewHome extends React.Component<HomeProps> {
  render() {
    return (
      <Context.Consumer>
        {ctx => {
          if (!ctx) {
            return null;
          }
          return (
            <Container id="projects">
              {this.props.featuredProjects.filter( project => project.featured == true ).map(project => {
                return (
                  <ViewFeaturedProject
                    key={project.id}
                    project={project}
                    ctx={ctx}
                  />
                );
              })}
              
            </Container>
          );
        }}
      </Context.Consumer>
    );
  }
}

export default withRouteData(ViewHome);


{/* <ViewProjectArchive
                ctx={ctx}
                archivedProjects={this.props.archivedProjects}
                filter={{
                  featured: {
                    eq: false
                  }
                }}
              /> */}